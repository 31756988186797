import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Body } from '../modules/Typography';
import Modal from '../modules/Modal';

const Leader = props => {
    const [modalOpen, setModal] = useState(false);

    const handleModal = () => {
        setModal(!modalOpen);

        if (!modalOpen) {
            document.getElementsByClassName('TopBar')[0].classList.add('modal-open');
            document.getElementsByClassName('TopHeader')[0].classList.add('modal-open');
        } else {
            document.getElementsByClassName('TopBar')[0].classList.remove('modal-open');
            document.getElementsByClassName('TopHeader')[0].classList.remove('modal-open');
        }
    };

    const { displayImage, name, title } = props.leader.attributes;

    return (
        <>
            {props.small ? (
                <div onClick={handleModal} className='About__leadership__smallcontainer'>
                    <div>
                        <div className='About__leadership__halo__small'>
                            <img className='About__leadership__photo__small' src={displayImage.data ? displayImage.data.attributes.url : '#'} />
                        </div>
                    </div>
                    <div className='flex column flex-start button'>
                        <Body block className='About__leadership__name__small'>{name}</Body>
                        <Body number={2} block className='About__leadership__title__small'>{title}</Body>
                    </div>
                </div>

            ) : (
                <>
                    <div onClick={handleModal} className='About__leadership__icon'>
                        <div className='About__leadership__halo' />
                        <img className='About__leadership__photo' src={displayImage.data ? displayImage.data.attributes.url : '#'} />
                        <div className='About__leadership__card'>
                            <Body block className='About__leadership__name'>{name}</Body>
                            <Body number={2} block className='About__leadership__title'>{title}</Body>
                        </div>
                    </div>
                </>
            )}
            {modalOpen &&
            <Modal
                show={modalOpen}
                data={props.leader}
                leader
                content={props.overlayContent}
                handleClose={handleModal}
            />}
        </>
    );
};

Leader.propTypes = {
    small: PropTypes.bool
};

export default Leader;
