import React, { useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import parseMarkdown from '../../utils/markdownParser';
import { GET_HOME, GET_ABOUT } from '../../queries';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading, Body } from '../modules/Typography';
import rightArrow from '../../images/right_arrow.png';
import leftArrow from '../../images/left_arrow.png';
import { Container, Row, Col } from '../modules/Grid';
import Feeds from '../home/Feeds';
import FeaturedNews from '../home/FeaturedNews';
import Loading from '../modules/Loading';
// import Button from '../modules/Button';

export const HomeContext = React.createContext();

const Home = props => {
    const { data, loading } = useQuery(GET_HOME);
    const { data: about } = useQuery(GET_ABOUT);
    const { isMobile } = useContext(WidthContext);
    const { headerImage, headerTitle, featured_news: featured } = data ? data.homePage.data.attributes : [];
    const { businessDescription } = about ? about.aboutPage.data.attributes : {};

    if (loading || !about) return <Loading />;
    return (
        <HomeContext.Provider value={{ }}>
            <div className='Home'>
                <Helmet><title>The Walt Disney Studios</title></Helmet>
                <Carousel
                    showArrows
                    autoPlay
                    infiniteLoop
                    interval={5000}
                    transitionTime={0}
                    showThumbs={false}
                    stopOnHover={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <button
                                type='button'
                                onClick={onClickHandler}
                                title={label}
                                className='control-arrow control-prev'
                            ><img src={leftArrow} />
                            </button>)}
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <button
                                type='button'
                                onClick={onClickHandler}
                                title={label}
                                className='control-arrow control-next'
                            ><img src={rightArrow} />
                            </button>
                        )}
                >
                    {headerImage && headerImage.map((slide, idx) => (
                        <Fragment key={idx}>
                            <div className='Home__hero__logo'>
                                <img className='' src={slide.business.data.attributes.logo.data ? slide.business.data.attributes.logo.data.attributes.url : '#'} />
                            </div>
                            <Heading heavy className='Home__hero__filmtitle padded' number={isMobile ? 7 : 5}>{slide.filmTitle ? slide.filmTitle : ''}
                                <Body block='true' number={isMobile ? 2 : 1} className='Home__hero__copyright'>{slide.copyright ? slide.copyright : ''}
                                </Body>
                            </Heading>

                            <div className='Slider__Slide' key={slide.image.data.attributes.url} id={`slide-${idx}`}>
                                <div className='Home__hero__background'>
                                    <Link to={`/business/${slide.business.data.attributes.slug}`}>
                                        <div className='Home__hero button' style={{ backgroundImage: `url("${slide.image.data ? slide.image.data.attributes.url : '#'}")` }}>
                                            <Heading className='Home__hero__title Home__hero__text' block number={2}>{headerTitle}</Heading>

                                        </div>

                                    </Link>
                                </div>
                            </div>
                        </Fragment>
                    ))}
                </Carousel>
                {/* {!isMobile && <Subtitle className='Home__hero__subtitle Home__hero__text' block>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Subtitle>}
                    <Button className='Home__hero__button' type='secondary'>Learn More</Button> */}
            </div>
            <Container className='Home__content'>
                <Row>
                    <Col sm={8}>
                        <div className='Home__section'>
                            <Heading number={isMobile ? 5 : 4}>Featured News</Heading>
                            <div className='heading--underline' />
                            <FeaturedNews news={featured.data || data.newsItemses.data} />
                        </div>
                        <div className='Home__section'>
                            <Heading number={isMobile ? 5 : 4}>About</Heading>
                            <div className='heading--underline' />
                            <div block='true'>{parseMarkdown(businessDescription)}
                            </div>
                            <Body className='Home__link' block='true' color='primary-light'><Link to='/about'>Learn more</Link></Body>
                        </div>
                        <div className='Home__section'>
                            <Heading number={isMobile ? 5 : 4}>FAQ</Heading>
                            <div className='heading--underline' />
                            <Body block className='Home__faq__text'>Find answers to your questions about The Walt Disney Studios.</Body>
                            <Body className='Home__link' block='true' color='primary-light'><Link to='/faqs'>Visit our FAQs</Link></Body>
                        </div>
                    </Col>
                    {/* <Col sm={4} smOffset={2}>
                        <Feeds />
                    </Col> */}
                </Row>
            </Container>
        </HomeContext.Provider>
    );
};

export default Home;
