import React, { useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import parseMarkdown from '../../utils/markdownParser';
import { GET_FAQ } from '../../queries';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading, Body } from '../modules/Typography';
import Loading from '../modules/Loading';
import { Container, Row, Col } from '../modules/Grid';
import Accordion from '../modules/Accordion';

const Faqs = props => {
    const { data, loading } = useQuery(GET_FAQ);
    const { headerTitle, headerImage, faqSection } = data ? data.faqPage.data.attributes : {};
    const { isMobile } = useContext(WidthContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading) return <Loading />;

    return (
        <div className='FAQs'>
            <Helmet><title>FAQs</title></Helmet>
            <div className='Home__hero flex column center ' style={{ backgroundImage: `url("${headerImage.data ? headerImage.data.attributes.url : '#'}")` }}>
                <Body className='Home__hero__caption'>{headerTitle}</Body>
            </div>
            <Container className='Home__content'>
                <Row>
                    <Col sm={12}>
                        <div className='Home__section'>
                            <Heading number={isMobile ? 5 : 4}>Frequently Asked Questions</Heading>
                            <div className='heading--underline' />
                        </div>
                        {faqSection && faqSection.map((item) => (
                            <div className='Home__section' key={item.id}>
                                <Heading number={isMobile ? 6 : 5}>{item.title}</Heading>
                                <Body block>{parseMarkdown(item.description)}
                                </Body>
                                {item.QA.map((faq) => (
                                    <Accordion
                                        key={faq.id}
                                        title={faq.question}
                                    >
                                        <Body block>{parseMarkdown(faq.answer)}
                                        </Body>
                                    </Accordion>

                                ))}
                                <div className='Faq__underline' />

                            </div>

                        ))}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

Faqs.propTypes = {

};

export default Faqs;
