import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewsCard from './NewsCard';
import Loading from '../modules/Loading';

const BusinessNewsBlock = props => {
    const newsItems = props.data;

    if (!newsItems) return <Loading />;
    return (
        <>
            <div className='FeaturedNews'>
                {newsItems.map((news) => (
                    <div className='FeaturedNews__business' key={news.id}>
                        <Link to={`/newsdetail/${news.attributes.slug}`}>
                            <NewsCard small title={news.attributes.title} business={news.attributes.businesses.length ? news.attributes.businesses.data[0].attributes.name : ''} poster={news.attributes.poster.data != null} image={news.attributes.cardImage.data ? news.attributes.cardImage.data.attributes.url : (news.attributes.poster.data ? news.attributes.poster.data.attributes.url : '#')} date={news.attributes.date} />
                        </Link>

                    </div>
                ))}
            </div>
        </>
    );
};

BusinessNewsBlock.propTypes = {
    data: PropTypes.array

};

export default BusinessNewsBlock;
