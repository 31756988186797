import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Heading, Body } from './Typography';
import Icon from './Icon';
import moment from 'moment';
import parseMarkdown from '../../utils/markdownParser';
import Button from './Button';
import getRandomInt from '../../utils/getRandomInt';

const CustomModal = (props) => {
    const { title, poster, bio, image, displayImage, releaseDate, releaseInfo, genre, business, synopsis, rating, directedBy, cast, category, name, distribution, website } = props.data.attributes;
    const businessName = business ? business.data.attributes.name : '';

    function downloadPrompt (blob, fileName) {
        var a = document.createElement('a');
        a.download = fileName;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    const downloadImage = e => {
        e.preventDefault();
        const url = `${e.currentTarget.getAttribute('href')}?${getRandomInt(1000, 9999)}`;
        const fileName = e.currentTarget.getAttribute('download');

        fetch(url, {
            headers: new Headers({
                Origin: window.location.origin
            }),
            mode: 'cors'
        })
            .then(response => response.blob())
            .then(blob => {
                const blobUrl = window.URL.createObjectURL(blob);
                downloadPrompt(blobUrl, fileName);
            })
            .catch(e => console.error(e));
    };

    return (
        <Modal show={props.show} onHide={props.handleClose} centered>
            <div className='TopBar' />

            <Button className='flex self-flex-end' variant='link' onClick={props.handleClose}>
                <Icon name='close' />
            </Button>
            {props.film &&
                <div className='flex'>
                    <div className='modal__inner__container film'>
                        <img className='modal__filmposter padded' src={poster ? poster.data.attributes.url : '#'} />
                        <div className='modal__content flex column align-flex-start padded'>
                            <div>
                                <Heading className='modal-header-text' number={5}>{title}</Heading>
                            </div>
                            <Body className='modal__content__filmdetails'>{businessName && businessName + '  |'} {rating && ' ' + rating + ' | '} {releaseInfo && releaseInfo + '  |' || moment(releaseDate).format('MMMM D, YYYY') + '  | '} {category !== 'None' && category && category + '  |  '}{genre && genre}</Body><br />
                            <Body>{synopsis}</Body><br />

                            {directedBy &&
                            <div>
                                <Heading className='block' number={6}>Directed By</Heading>
                                <Body>{directedBy}</Body><br /><br />
                            </div>}
                            {cast &&
                            <div>
                                <Heading className='block' number={6}>Cast</Heading>
                                <Body>{cast}</Body><br /><br />
                            </div>}
                            {distribution &&
                            <div>
                                <Heading className='block' number={6}>Distribution</Heading>
                                <Body>{distribution}</Body><br /><br />
                            </div>}
                            {website &&
                            <div>
                                <Heading className='block' number={6}>Website</Heading>
                                <Body><a target='_blank' href={website} rel='noreferrer'>{website}</a></Body><br /><br />
                            </div>}
                        </div>
                    </div>
                </div>}
            {props.leader &&
                <div>
                    <div>
                        <div className='modal__inner__container'>

                            <img className='modal__leader__image flex align-self-baseline' src={displayImage ? displayImage.data.attributes.url : '#'} />
                            <div className='modal__leader__details flex row flex-start'>
                                <div>
                                    <Heading className='modal-header-text' number={5}>{name}</Heading>
                                    <Body className='modal__leader__title' block>{title}</Body><br />
                                </div>
                                <a href={image ? image.data.attributes.url : '#'} download={`${name.replace(' ', '_')}.jpg`} onClick={downloadImage}>
                                    <div className='About__leadership__button'>
                                        <Button className='block' type='secondary'>Download JPG</Button>
                                    </div>
                                </a>
                                <br />
                            </div>
                            <div>
                                <Body>{parseMarkdown(bio)}</Body>
                            </div>
                        </div>
                    </div>
                </div>}
        </Modal>
    );
};

CustomModal.propTypes = {
    show: PropTypes.bool,
    data: PropTypes.object,
    film: PropTypes.bool,
    business: PropTypes.bool,
    leader: PropTypes.bool,
    handleClose: PropTypes.func
    // content: PropTypes.string
};

export default CustomModal;
