import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';

const Layout = props => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (window.s) {
            window.s.pageName = pathname;
            window.s.t();
        }
    }, [pathname, window.s]);

    useEffect(() => {
        if (window.OneTrust && window.OneTrust.ToggleInfoDisplay) {
            document.getElementById('ot-sdk-btn').addEventListener('click', window.OneTrust.ToggleInfoDisplay);
        }
    }, []);

    return (
        <div className='layout'>
            <div className='TopBar' />
            <Header />
            {props.children}
            <Footer />
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.object
};

Layout.defaultProps = {};

export default Layout;
