import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Body } from '../modules/Typography';

const NewsItem = props => {
    const { isMobile } = useContext(WidthContext);

    return (
        <div className='FeaturedNews__slide'>
            <div className='FeaturedNews__slide__image' style={{ backgroundImage: `url("${props.image}")` }} />
            <Body block className='FeaturedNews__slide__text' number={isMobile ? 2 : 1}>{props.title}</Body>
        </div>
    );
};

NewsItem.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string
};

export default NewsItem;
