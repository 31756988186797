const regex = /https:\/\/t.co\/\w*\d*[^\s]/g;

function twitterFeedParser (text) {
    let truncText = text;
    const hyperlink = text.match(regex) || [];

    if (hyperlink.length > 0) {
        truncText = text.replace(regex, '');
    }

    return [hyperlink, truncText];
}

export default twitterFeedParser;
