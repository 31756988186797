import React from 'react';
import { Body } from '../modules/Typography';

const Footer = props => {
    return (
        <div className='Footer flex column align-center center'>
            <div className='Footer__wrapper flex center'>
                <a href='https://disneytermsofuse.com/' target='_blank' rel='noreferrer' className='Footer__link'>Terms of Use</a>
                <div className='Footer__divider' />
                <a href='https://support.disney.com/hc/en-us/articles/360000829466-Legal-Notices' target='_blank' rel='noreferrer' className='Footer__link'>Legal Notices</a>
                <div className='Footer__divider' />
                <a href='https://privacy.thewaltdisneycompany.com/en/' target='_blank' rel='noreferrer' className='Footer__link'>Privacy Policy</a>
                <div className='Footer__divider' />
                <a href='https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/your-us-state-privacy-rights/' target='_blank' rel='noreferrer' className='Footer__link'>Your US State Privacy Rights</a>
                <div className='Footer__divider' />
                <a href='https://privacy.thewaltdisneycompany.com/en/for-parents/childrens-online-privacy-policy/' target='_blank' rel='noreferrer' className='Footer__link'>Children's Online Privacy Policy</a>
                <div className='Footer__divider' />
                <a href='http://preferences-mgr.truste.com/?type=disneycolor&affiliateId=115' target='_blank' rel='noreferrer' className='Footer__link'>Interest-Based Ads</a>
                <div className='Footer__divider' />
                <button id='ot-sdk-btn' className='ot-sdk-show-settings Footer__link'>Do Not Sell or Share My Personal Information</button>
            </div>
            <Body className='Footer__copyright block center' number={2}>©Disney 2022 All Rights Reserved.</Body>
        </div>
    );
};

export default Footer;
