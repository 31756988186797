import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Paginator from 'react-hooks-paginator';
import { SEARCH } from '../../queries';
import Loading from '../modules/Loading';
import { Subtitle, Heading } from '../modules/Typography';
import searchParser from '../../utils/searchParser';
import Icon from '../modules/Icon';

const pageLimit = 10;
const SearchResults = props => {
    const { query } = useParams();
    const { data, loading, error } = useQuery(SEARCH, { variables: { searchTerm: decodeURIComponent(query) } });
    const [newsItems, setNewsItems] = useState([]);

    const [offset, setOffset] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentNewsItems, setCurrentNewsItems] = useState([]);
    const [numPerPage, setNumPerPage] = useState('');

    useEffect(() => {
        if (data) {
            setCurrentNewsItems([]);
            setCurrentPage(0);
            setOffset(0);
            setNewsItems(data.newsItemses);
        }
    }, [data]);

    useEffect(() => {
        if (!newsItems.data) { newsItems.data = []; }
        const slicedNews = newsItems.data.slice(offset, offset + pageLimit);

        setCurrentNewsItems(slicedNews);

        if (newsItems.data.length === 0) {
            setNumPerPage('0');
        } else if (slicedNews.length < 10) {
            setNumPerPage(`${1 + offset}-${newsItems.data.length}`);
        } else {
            if (offset === 0) {
                setNumPerPage(`${1}-${pageLimit}`);
            } else {
                setNumPerPage(`${1 + offset}-${pageLimit + offset}`);
            }
        }
    }, [offset, newsItems]);

    if (loading) return <Loading />;
    if (error) console.error({ error });

    return (
        <div className='SearchResults'>
            <Heading number={4} block className='Document__title'>Search Results</Heading>
            <div className='heading--underline' />
            <div className='SearchResults__Paper'>
                <div className='flex SearchResults__Header'>
                    <header className='SearchResults__results__header'>
                        <Subtitle block number={3}>Search for "{decodeURIComponent(query)}"</Subtitle>
                        <Subtitle block number={2}>{numPerPage} of {newsItems.data.length} result{newsItems.data.length !== 1 ? 's' : ''}</Subtitle>
                    </header>
                </div>
                <div className='SearchResults__results__container'>
                    {currentNewsItems.map((doc, idx) => {
                        const { slug, title, story } = doc.attributes;

                        return (
                            <div className='SearchResults__result' key={`result-${idx}`}>
                                <Link to={`/newsdetail/${slug}`}>
                                    <Subtitle block heavy number={3} color='primary'>{title}</Subtitle>
                                </Link>
                                <Subtitle className='SearchResults__result__description' block number={2}>{story[0] ? searchParser(story[0].text) : ''}</Subtitle>
                            </div>
                        );
                    })}
                    {currentNewsItems.length === 0 || newsItems.data.length <= pageLimit
                        ? null
                        : <div className='flex flex-start'>
                            <Icon className='button' name='caret-left' onClick={() => setCurrentPage(currentPage - 1)} />
                            <Paginator
                                totalRecords={newsItems.data.length}
                                pageLimit={pageLimit}
                                pageNeighbours={10}
                                setOffset={setOffset}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                            <Icon className='button' name='caret-right' onClick={() => setCurrentPage(currentPage + 1)} />
                        </div>}
                </div>
            </div>
        </div>
    );
};

export default SearchResults;
