import React, { useEffect, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import parseMarkdown from '../../utils/markdownParser';
import { GET_BIZ_DETAIL } from '../../queries';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading, Body } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import Icon from '../modules/Icon';
import youtubeLogo from '../../images/youtube_icon.png';
import Loading from '../modules/Loading';
import FeaturedTitles from '../home/FeaturedTitles';
import BusinessNewsBlock from '../home/BusinessNewsBlock';

const BusinessDetail = props => {
    const { isMobile } = useContext(WidthContext);
    const { slug } = useParams();
    const { data, loading } = useQuery(GET_BIZ_DETAIL, { variables: { slug: slug } });
    const { name, description, facebook, twitter, instagram, youtube, website, logo, films, news_items } = data ? data.businesseses.data[0].attributes : {};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading) return <Loading />;

    return (
        <div className='About'>
            <Helmet><title>{name}</title></Helmet>
            <div className='Home__hero__small flex column center' style={{ background: 'linear-gradient(#2338F4, #00B7C7)' }}>
                <Container>
                    <Col className='flex center column'>
                        <img src={logo.data ? logo.data.attributes.url : '#'} />
                        <div className='Business__icons'>
                            {website && <a className='button' target='_blank' rel='noreferrer' href={website}><Icon size={isMobile ? 'medium' : 'large'} name='share' /></a>}
                            {facebook && <a className='button' target='_blank' rel='noreferrer' href={facebook}><Icon size={isMobile ? 'medium' : 'large'} name='facebook' /></a>}
                            {twitter && <a className='button' target='_blank' rel='noreferrer' href={twitter}><Icon size={isMobile ? 'medium' : 'large'} name='twitter' /></a>}
                            {instagram && <a className='button' target='_blank' rel='noreferrer' href={instagram}><Icon size={isMobile ? 'medium' : 'large'} name='instagram' /></a>}
                            {youtube && <a className='button' target='_blank' rel='noreferrer' href={youtube}><img className='Business__small' src={youtubeLogo} /></a>}
                        </div>
                    </Col>
                </Container>
            </div>
            <Container className='Home__content'>
                <Row>
                    <Col lg={7}>
                        <div className='Home__section'>
                            <Heading number={isMobile ? 5 : 4}>About</Heading>
                            <div className='heading--underline' />
                            <Body className='' block>{parseMarkdown(description)}</Body>
                        </div>
                        {!isMobile &&
                        <div className='Home__section'>
                            <Heading number={4}>Featured Titles</Heading>
                            <div className='heading--underline' />
                            <FeaturedTitles films={films.data} />
                        </div>}

                    </Col>
                    <Col lg={4} lgOffset={1}>
                        <div className='Home__section'>
                            <Heading number={isMobile ? 6 : 5}>Latest News</Heading>
                            <div className='Feeds__underline' />
                            <BusinessNewsBlock data={news_items.data} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

BusinessDetail.propTypes = {

};

export default BusinessDetail;
