import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { WidthContext } from '../../contexts/WidthContextProvider';
import Icon from './Icon';
import TextField from './TextField';
import Button from '../modules/Button';

const Search = ({ isTextFieldOpen, iconClick, callback, hasCloseIcon, handleCloseIconClick }) => {
    const { isMobile } = useContext(WidthContext);
    const [searchTerm, setSearchTerm] = useState('');
    const inputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (isTextFieldOpen) inputRef.current.focus();
    }, [isTextFieldOpen]);

    const handleSubmit = () => {
        const _searchTerm = encodeURIComponent(searchTerm);

        if (isMobile) callback();

        navigate(`/search-results/${_searchTerm}`);
        setSearchTerm('');
    };

    const classNames = isMobile
        ? 'Search__mobile'
        : 'Search';

    return (
        <>
            {
                isTextFieldOpen
                    ? <div className='Search__wrapper'>
                        <TextField
                            ref={inputRef}
                            placeholder='Type to Search...'
                            className={classNames}
                            padded={false}
                            onChange={e => setSearchTerm(e.target.value)}
                            value={searchTerm}
                            closeIcon={hasCloseIcon ? 'close-round' : ''}
                            closeIconClick={handleCloseIconClick}
                            onSubmit={handleSubmit}
                        />
                        {isMobile
                            ? <Button className='Search__button' type='secondary' onClick={handleSubmit}>Search</Button>
                            : null}
                      </div>
                    : <Icon
                            className='NavMenu__search button'
                            name='search'
                            onClick={iconClick}
                      />
            }
        </>
    );
};

export default Search;
