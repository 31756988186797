import React, { useEffect, useState, useContext, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useLocation, Link } from 'react-router-dom';
import parseMarkdown from '../../utils/markdownParser';
import { GET_ABOUT, GET_FILM_DETAIL } from '../../queries';
import { Helmet } from 'react-helmet-async';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading, Body } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import Modal from '../modules/Modal';
import Business from '../home/Business';
import FeaturedTitles from '../home/FeaturedTitles';
import Leader from '../home/Leader';
// import PageTimeline from '../home/PageTimeline'; to be added in the future when content is ready
import mickey from '../../images/Mickey.png';
import Loading from '../modules/Loading';
import Icon from '../modules/Icon';
import Paper from '../modules/Paper';
import Button from '../modules/Button';

const About = props => {
    const location = useLocation();
    const { data, loading } = useQuery(GET_ABOUT);
    const { headerTitle, headerImage, businessHeader, businessDescription, textBlock, films, businesses, leaders } = data ? data.aboutPage.data.attributes : {};
    const { isMobile, size, documentWidth } = useContext(WidthContext);
    const film = location.pathname.substring(location.pathname.lastIndexOf('/'), location.pathname.length);
    const slug = film.substring(1);
    const { data: filmData } = useQuery(GET_FILM_DETAIL, { variables: { slug: slug } });

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location.pathname.includes('filmdetail')) {
            setModal(true);
        }
    }, []);

    const handleModal = () => {
        setModal(!modalOpen);
    };

    if (loading) return <Loading />;

    return (
        <div className='About'>
            <Helmet><title>About</title></Helmet>
            <div className='Home__hero center flex column' style={{ backgroundImage: `url("${headerImage.data ? headerImage.data.attributes.url : '#'}")` }}>
                <Body className='Home__hero__caption'>{headerTitle}</Body>
            </div>
            <Container className='Home__content'>
                <Row>
                    <Col sm={12} lg={8}>
                        <div className='Home__section'>
                            <Heading number={isMobile ? 5 : 4}>{businessHeader}</Heading>
                            <div className='heading--underline' />
                            <Body block>{parseMarkdown(businessDescription)}
                            </Body>
                        </div>
                        {(documentWidth <= 995) &&
                        <div className='About__leadership'>
                            <Heading number={isMobile ? 6 : 5}>Leadership</Heading>
                            <div className='Feeds__underline' />
                            <div className='About__leadership__aboutcontainer'>
                                {leaders.data &&
                            leaders.data.map((leader, idx) => (
                                <Leader key={idx} leader={leader} small />
                            ))}
                                {/* <div className='About__leadership__smallcontainer'>
                                    <Link to='/leadership' className='About__leadership__smallcontainer'>
                                        <div className='About__leadership__halo__small'>
                                            <img className='About__leadership__photo__small' src={mickey} />
                                        </div>
                                        <div className='flex column flex-start'>
                                            <Body block className='About__leadership__name__small'>All Team Members</Body>
                                        </div>
                                    </Link>
                                </div> */}
                            </div>
                        </div>}
                        {!isMobile && size !== 'md' &&
                        <div className='Home__section'>
                            <Heading number={isMobile ? 5 : 4}>Featured Titles</Heading>
                            <div className='heading--underline' />
                            <FeaturedTitles films={films.data} />
                            {!location.pathname.includes('about') &&
                            <Modal
                                show={modalOpen}
                                data={filmData.films.data[0]}
                                film
                                content={props.overlayContent}
                                handleClose={handleModal}
                            />}
                        </div>}
                        <div className='Home__section'>
                            <Heading number={isMobile ? 5 : 4}>Our Businesses</Heading>
                            <div className='heading--underline' />
                        </div>
                        <div className='Home__section'>
                            <div className='About__businesses'>
                                <>
                                    {businesses.data.map((biz, i) => (
                                        <Fragment key={i}>
                                            <Business key={i} business={biz} />
                                        </Fragment>
                                    ))}
                                </>
                            </div>

                        </div>
                        {textBlock.map((block) => (
                            <div key={block.id} className='Home__section'>
                                <Heading number={isMobile ? 5 : 4}>{block.header}</Heading>
                                <div className='heading--underline' />
                                <Body block>{parseMarkdown(block.paragraph)}</Body>
                            </div>
                        ))}
                    </Col>
                    {(documentWidth >= 996) &&
                    <Col sm={8} lg={3} xl={3} smOffset={0} lgOffset={1}>
                        <div className='About__leadership'>
                            <div className='About__leadership__aboutcontainer'>
                                <Heading number={isMobile ? 6 : 5}>Leadership</Heading>
                                <div className='Feeds__underline' />
                                {leaders.data &&
                                leaders.data.map((leader, idx) => (
                                    <Leader key={idx} leader={leader} small />
                                ))}
                                {/* <div className='About__leadership__smallcontainer'>
                                    <Link to='/leadership' className='About__leadership__smallcontainer'>
                                        <div className='About__leadership__halo__small'>
                                            <img className='About__leadership__photo__small' src={mickey} />
                                        </div>
                                        <div className='flex column flex-start'>
                                            <Body block className='About__leadership__name__small'>All Team Members</Body>
                                        </div>
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                        {/* <PageTimeline data={timeline} /> */}
                    </Col>}
                </Row>
            </Container>
        </div>
    );
};

About.propTypes = {

};

export default About;
