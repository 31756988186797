import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row } from '../modules/Grid';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading, Subtitle, Body } from '../modules/Typography';

const NewsCard = props => {
    const { size } = useContext(WidthContext);
    return (
        <>
            {props.small ? (
                <div className='NewsCard small'>
                    <Row>
                        <img className={props.poster != null && props.poster.attributes != null ? 'NewsCard__poster small' : 'NewsCard__image small'} src={`${props.image}`} />
                        <div className='NewsCard__details small flex column align-flex-start'>
                            <div className='flex column align-flex-start'>
                                <Body className='NewsCard__details__date small'>{moment(props.date).format('MMMM D, YYYY')}</Body>
                                <Body className='NewsCard__details__name' number={2}>{props.title}</Body>
                            </div>
                        </div>
                    </Row>
                </div>

            ) : (
                <div className='NewsCard'>
                    <img className={props.poster != null && props.poster.attributes != null ? 'NewsCard__poster' : 'NewsCard__image'} src={`${props.image}`} />
                    <div className='NewsCard__details flex column align-flex-start'>
                        <div className='flex column align-flex-start'>
                            {size !== 'xl' && size !== 'xxl' ? (
                                <Subtitle className='NewsCard__details__name' number={1}>{props.title}</Subtitle>
                            ) : (
                                <Heading className='NewsCard__details__name' number={6}>{props.title}</Heading>
                            )}
                            <Body className='NewsCard__details__date'>{moment(props.date).format('MMMM D, YYYY')}</Body>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

NewsCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    business: PropTypes.string,
    poster: PropTypes.bool,
    small: PropTypes.bool
};

export default NewsCard;
