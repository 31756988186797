import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Body } from '../modules/Typography';
import Modal from '../modules/Modal';

const FeatureTitle = props => {
    const [modalOpen, setModal] = useState(false);

    const handleModal = () => {
        setModal(!modalOpen);
    };

    const { title, poster } = props.film.attributes;

    return (
        <div className='FeaturedTitles__slide'>
            <div onClick={handleModal}><img className='FeaturedTitles__slide__image' src={poster.data ? poster.data.attributes.url : '#'} /></div>
            <Body onClick={handleModal} block className='About__film__name'>{title}</Body>
            <Body number={2} block className='About__film__title'>{moment(props.date).format('MMMM YYYY')}</Body>
            <Modal
                show={modalOpen}
                data={props.film}
                film
                content={props.overlayContent}
                handleClose={handleModal}
            />
        </div>
    );
};

FeatureTitle.propTypes = {
    title: PropTypes.string,
    film: PropTypes.object,
    date: PropTypes.string
};

export default FeatureTitle;
