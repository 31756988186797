import React, { useContext, useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { WidthContext } from '../../contexts/WidthContextProvider';
import logo from '../../images/logo.svg';
import { Subtitle } from '../modules/Typography';
import MobileMenu from './MobileMenu';
import Search from '../modules/Search';

const Header = props => {
    const location = useLocation();
    const { isMobile, size } = useContext(WidthContext);
    const [isStuck, setStuck] = useState(isMobile);
    const isAbout = location.pathname.includes('about') || location.pathname.includes('business') || location.pathname.includes('leadership');
    const isNewsPage = location.pathname.includes('news');
    const isFAQ = location.pathname.includes('faq');
    const isSearchResultsPage = location.pathname.includes('search-results');
    const isNewsDetailPage = location.pathname.includes('newsdetail');
    const [isSearchEnabled, setSearchEnabled] = useState(false);
    const [navContainerStyle, setNavContainerStyle] = useState({});
    const [searchBarStyle, setSearchBarStyle] = useState({});

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);
        return () => {
            document.removeEventListener('scroll', scrollHandler);
            document.body.classList.remove('no-scroll');
        };
    }, []);

    // whenever the URL changes close the search text box
    useEffect(() => {
        if (location.pathname === 'search-results') {
            if (isSearchEnabled) {
                setNavContainerStyle({ position: 'relative' });
                setSearchBarStyle({ position: 'absolute', width: '100%', right: '0' });
            }
        }
    }, [location.pathname]);

    const scrollHandler = () => {
        if (window.pageYOffset >= 1) {
            setStuck(true);
        } else if (!isMobile) {
            setStuck(false);
        }
    };

    useEffect(() => {
        if (isSearchEnabled) {
            setNavContainerStyle({ position: 'relative' });
            setSearchBarStyle({ position: 'absolute', width: '100%', height: '45px', right: '0' });
        } else {
            setNavContainerStyle({});
            setSearchBarStyle({});
        }
    }, [isSearchEnabled]);

    const handleCloseIconClick = () => {
        setSearchEnabled(false);
    };

    return (
        <>
            <header className={`TopHeader ${isMobile || isStuck || isSearchResultsPage || isNewsDetailPage ? 'stuck' : ''}`}>
                <div className='TopContainer'>
                    <div className='TopWrapper'>
                        <Link to='/'>
                            <img src={logo} width={isMobile ? '208px' : '315px'} />
                        </Link>

                        {
                            isMobile || size === 'md'
                                ? <MobileMenu />
                                : <nav style={navContainerStyle} className='NavContainer'>
                                    <ul className='NavMenu'>
                                        <li className={`NavMenu__item ${isAbout ? 'active' : ''}`}>
                                            <Link to='/about'>
                                                <Subtitle className='NavMenu__item__text' number={2}>ABOUT</Subtitle>
                                            </Link>
                                        </li>
                                        <li className={`NavMenu__item ${isNewsPage ? 'active' : ''}`}>
                                            <Link to='/news'>
                                                <Subtitle className='NavMenu__item__text' number={2}>NEWS</Subtitle>
                                            </Link>
                                        </li>
                                        <li className={`NavMenu__item ${isFAQ ? 'active' : ''}`}>
                                            <Link to='/faqs'>
                                                <Subtitle className='NavMenu__item__text' number={2}>FAQ</Subtitle>
                                            </Link>
                                        </li>
                                        <li className='NavMenu__item' style={searchBarStyle}>
                                            <Search
                                                isTextFieldOpen={isSearchEnabled}
                                                iconClick={() => setSearchEnabled(!isSearchEnabled)}
                                                hasCloseIcon
                                                handleCloseIconClick={handleCloseIconClick}
                                            />
                                        </li>
                                    </ul>
                                </nav>
                        }
                    </div>
                </div>
            </header>
        </>
    );
};

Header.propTypes = {
    // search: PropTypes.bool,
    // setSearch: PropTypes.func

};

export default Header;
