import React from 'react';
import WidthContextProvider from '../contexts/WidthContextProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import '../styles/main.scss';
import GraphiqlEditor from './GraphiqlEditor';
import Home from './pages/Home';
import About from './pages/About';
import News from './pages/News';
import FAQs from './pages/Faqs';
import Leadership from './pages/Leadership';
import NewsDetail from './pages/NewsDetail';
import BusinessDetail from './pages/BusinessDetail';
import SearchResults from './pages/SearchResults';
import Layout from './layout/Layout';

// const routes = [
//     { path: '/', component: Home },
//     { path: '/about', component: {<About />} },
//     { path: '/news', component: '<News />' },
//     { path: '/faqs', component: '<FAQs />' },
//     { path: '/leadership', component: '<Leadership />' },
//     { path: '/leadership/:slug', component: '<Leadership />' },
//     { path: '/newsdetail/:slug', component: '<NewsDetail />' },
//     { path: '/filmdetail/:slug', component: '<About />' },
//     { path: '/search-results/:query', component: '<SearchResults />' },
//     { path: '/business/:slug', component: '<BusinessDetail />' },
//     // { path: '/graphiql', component: GraphiqlEditor },
//     { path: '*', component: '<Home />' }

// ];

function App () {
    return (
        <WidthContextProvider>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/about' element={<About />} exact />
                        <Route path='/news' element={<News />} exact />
                        <Route path='/faqs' element={<FAQs />} exact />
                        <Route path='/leadership' element={<Leadership />} exact />
                        <Route path='/leadership/:slug' element={<Leadership />} exact />
                        <Route path='/newsdetail/:slug' element={<NewsDetail />} exact />
                        <Route path='/filmdetail/:slug' element={<About />} exact />
                        <Route path='/search-results/:query' element={<SearchResults />} exact />
                        <Route path='/business/:slug' element={<BusinessDetail />} exact />
                        <Route path='*' element={<Home />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </WidthContextProvider>
    );
}
/*
                                <Route key={key} path={path} element={component} exact />

                        */
export default App;
