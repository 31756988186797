import { gql } from '@apollo/client';

export const SEARCH = gql`
      query search($searchTerm: String) {
        newsItemses(sort: "title:asc",
                  pagination: {start: 0, limit: 50},
                  filters: {
                    or: [
                      { title: {containsi: $searchTerm }},
                      { tags:  {containsi: $searchTerm }}
                    ]
                  }
                ) {
        data {
          id
          attributes {
            title
            slug
            story {
            ...on ComponentTextBlockTextBlock {
                id
                text
              }
            }
          }
        }
      }
    }
`;
