import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import PortalReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';
import { HashLink as Link } from 'react-router-hash-link';
import IconButton from '../modules/IconButton';
import { Subtitle } from '../modules/Typography';
import Search from '../modules/Search';

const MobileMenu = props => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSearchEnabled, setSearchEnabled] = useState(false);

    const openPanel = () => {
        document.body.classList.add('no-scroll');
        setPanelOpen(true);
    };
    const closePanel = () => {
        document.body.classList.remove('no-scroll');
        setPanelOpen(false);
    };
    const openMenu = () => {
        document.getElementsByClassName('TopHeader')[0].classList.add('mobile-menu-open');
        document.getElementsByClassName('TopBar')[0].classList.add('search-open');
        setMenuOpen(true);
    };
    const closeMenu = () => {
        document.getElementsByClassName('TopHeader')[0].classList.remove('mobile-menu-open');
        document.getElementsByClassName('TopBar')[0].classList.remove('search-open');
        setMenuOpen(false);
    };
    const openSearch = () => {
        document.getElementsByClassName('TopHeader')[0].classList.add('search-open');
        document.getElementsByClassName('TopBar')[0].classList.add('search-open');
        setSearchEnabled(true);
    };
    const closeSearch = () => {
        document.getElementsByClassName('TopHeader')[0].classList.remove('search-open');
        document.getElementsByClassName('TopBar')[0].classList.remove('search-open');
        setSearchEnabled(false);
    };

    const handlePanel = switchToPanel => {
        if (isPanelOpen) {
            if (switchToPanel === 'menu') {
                if (isMenuOpen) {
                    closePanel();
                    closeMenu();
                } else {
                    closeSearch();
                    closePanel();
                }
            }
            if (switchToPanel === 'search') {
                closeMenu();
                openSearch();
            }
            if (switchToPanel === 'close') {
                closeSearch();
                closePanel();
            }
        }
        if (!isPanelOpen) {
            if (switchToPanel === 'menu') {
                openPanel();
                openMenu();
            }
            if (switchToPanel === 'search') {
                openPanel();
                openSearch();
            }
        }
    };

    return (
        <div className='NavContainer__mobile'>
            <Search
                isTextFieldOpen={isSearchEnabled}
                iconClick={() => handlePanel('search')}
                callback={() => handlePanel('close')}
            />
            <IconButton
                name={isPanelOpen && (isMenuOpen || isSearchEnabled) ? 'close' : 'density-comfy'}
                onClick={() => handlePanel('menu')}
            />
            <MobileModal
                isPanelOpen={isPanelOpen}
                isMenuOpen={isMenuOpen}
                isSearchEnabled={isSearchEnabled}
            />
        </div>
    );
};

const MobileModal = ({ isPanelOpen, isSearchEnabled }) => {
    return PortalReactDOM.createPortal(
        <Menu
            className='Mobile__menu'
            noOverlay right width='100%'
            disableOverlayClick
            disableAutoFocus
            isOpen={isPanelOpen}
        >
            {isSearchEnabled
                ? null
                : <ul className='NavMenu'>
                    <li className='NavMenu__item'>
                        <Link to='/about'>
                            <Subtitle number={2}>ABOUT</Subtitle>
                        </Link>
                    </li>
                    <li className='NavMenu__item'>
                        <Link to='/news'>
                            <Subtitle number={2}>NEWS</Subtitle>
                        </Link>
                    </li>
                    <li className='NavMenu__item'>
                        <Link to='/faqs'>
                            <Subtitle number={2}>FAQ</Subtitle>
                        </Link>
                    </li>
                  </ul>}
        </Menu>,
        document.getElementById('mobile-modal')
    );
};

MobileMenu.propTypes = {
    isMenuOpen: PropTypes.bool,
    setMenuOpen: PropTypes.func,
    setOpenSearch: PropTypes.func

};

export default MobileMenu;
