// import React, { useContext } from 'react';
// import { Heading } from '../components/modules/Typography';
// import { renderToString } from 'react-dom/server';
// import ReactHtmlParser from 'html-react-parser';
// import DOMPurify from 'dompurify';
// import { WidthContext } from '../contexts/WidthContextProvider';

// export default function parseMarkdown (markdownText) {
//     const { isMobile } = useContext(WidthContext);
//     if (!markdownText) return '';
//     const htmlText = markdownText
//         .replace(/^# (.*$)/gim, renderToString(<Heading block number={isMobile ? 2 : 1} heavy>$1</Heading>))
//         .replace(/^## (.*$)/gim, renderToString(<Heading block number={isMobile ? 3 : 2} heavy>$1</Heading>))
//         .replace(/^### (.*$)/gim, renderToString(<Heading block number={isMobile ? 4 : 3} heavy>$1</Heading>))
//         .replace(/^#### (.*$)/gim, renderToString(<Heading block number={isMobile ? 5 : 4} heavy>$1</Heading>))
//         .replace(/^##### (.*$)/gim, renderToString(<Heading block number={isMobile ? 6 : 5} heavy>$1</Heading>))
//         .replace(/^###### (.*$)/gim, renderToString(<Heading block number={6} heavy>$1</Heading>))
//         .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>')
//         .replace(/~~(.*)~~/gim, '<s>$1</s>')
//         .replace(/\*(.*)\*/gim, '<i>$1</i>')
//         .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
//         .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
//         .replace(/^> (.*$)/gim, '<blockquote>$1</blockquote>')
//         .replace(/```(.*)```/gim, '<code>$1</code>')
//         .replace(/\n\*(.*)/gim, '<code>$1</code>')
//         .replace(/^<ul>(.*$)/gim, '<ul>$1')
//         .replace(/^<\/ul>(.*$)/gim, '</ul>')
//         .replace(/^- (.*$)/gim, '<li>$1</li>')
//         .replace(/^<ol>(.*$)/gim, '<ol>$1')
//         .replace(/^<\/ol>(.*$)/gim, '</ol>')
//         .replace(/^[0-9]+. (.*$)/gim, '<li>$1</li>')
//         .replace(/\r$/gim, '<br />');
//         // .replace(/\n(.*)/gim, '<p>$1</p>');

//     return <div className='markdown-parser'>{ReactHtmlParser(DOMPurify.sanitize(htmlText.trim()), { FORBID_TAGS: Constants.FORBID_TAGS })}</div>;
// };

// export const Constants = {
//     FORBID_TAGS: ['script', 'iframe'],
//     ALLOWED_TAGS: []
// };

import React from 'react';

export default function parseMarkdown (markdownText) {
    return (
        <div className='markdown-parser'>
            <div dangerouslySetInnerHTML={{ __html: markdownText }} />
        </div>
    );
}
