import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { GET_LEADERS, GET_LEADERS_PREVIEW } from '../../queries';
import { Heading } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import Leader from '../home/Leader';
import Loading from '../modules/Loading';

const Leadership = props => {
    const { data, loading } = useQuery(GET_LEADERS);
    const { data: preview } = useQuery(GET_LEADERS_PREVIEW);
    const location = useLocation();
    const leader = location.pathname.substring(location.pathname.lastIndexOf('/'), location.pathname.length);
    const slug = leader.substring(1);
    const sortedLeaders = data ? [...data.leaders].sort((a, b) => a.data.attributes.order - b.data.attributes.order) : [];

    useEffect(() => {
        window.scrollTo(0, 0);
        window.location.replace('https://thewaltdisneycompany.com/about/#leadership');
    }, []);

    if (loading) return <Loading />;

    return (
        <div className='About'>
            <Helmet><title>Studio Leadership</title></Helmet>
            <div className='Home__hero__small center flex column' style={{ background: 'linear-gradient(#2338F4, #00B7C7)' }}>
                <Heading className='Home__hero__title Home__hero__text' block number={2}>Studio Leadership</Heading>
            </div>
            <Container className='Home__content'>
                <Row>
                    <Col sm={12} offset={2}>
                        <div className='Home__section'>
                            <Heading number={4}>Leadership Team</Heading>
                            <div className='heading--underline' />
                            <div className='About__leadership__container'>
                                {data && slug === 'leadership' ? (
                                    <>
                                        {sortedLeaders.map((leader, idx) => (
                                            <Leader key={idx} leader={leader} />
                                        ))}
                                    </>) : (preview && preview.leaders.filter(leader => leader.data.attributes.slug === slug).map(item => (<Leader key={item.data.attributes.id} leader={item} />))

                                )}
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );
};

Leadership.propTypes = {

};

export default Leadership;
