import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewsCard from '../home/NewsCard';
import { Container, Row, Col } from '../modules/Grid';
import Loading from '../modules/Loading';

const FeaturedNewsBlock = props => {
    const newsItems = props.data;

    if (!newsItems) return <Loading />;

    return (
        <>
            <div className='FeaturedNews'>
                {newsItems.map((news, idx) => (
                    <Col key={idx} className='news' xs={12} sm={5} md={5} lg={3}>

                        <div className='FeaturedNews__block' key={news.id}>
                            <div className='FeaturedNews__container'>

                                <Link to={`/newsdetail/${news.attributes.slug}`}>
                                    <NewsCard title={news.attributes.title} business={news.attributes.businesses.length ? news.attributes.businesses[0].data.attributes.name : ''} poster={news.attributes.poster.data != null} image={news.attributes.cardImage.data ? news.attributes.cardImage.data.attributes.url : (news.attributes.poster.data ? news.attributes.poster.data.attributes.url : '#')} date={news.attributes.date} />
                                </Link>
                            </div>
                        </div>
                    </Col>
                ))}
            </div>
        </>
    );
};

FeaturedNewsBlock.propTypes = {
    data: PropTypes.array

};

export default FeaturedNewsBlock;
