import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Body } from './Typography';
import Icon from './Icon';

const Accordion = props => {
    const [open, setOpen] = useState(props.initOpen);

    const toggle = () => setOpen(prev => !prev);

    return (
        <div className={`Accordion Accordion--${props.type} ${props.padded ? 'Accordion--padded' : ''}`}>
            <div className='Accordion__header' onClick={toggle}>
                <Icon name={open ? 'caret-down' : 'caret-right'} />
                <Body>{props.title}</Body>
            </div>
            {open && props.children}
        </div>
    );
};

export default Accordion;

Accordion.propTypes = {
    // title of the accordion
    title: PropTypes.string,

    // style of the accordion
    type: PropTypes.oneOf(['default']),

    // whether or not it intializes open
    initOpen: PropTypes.bool,

    // whether or not the accordion is padded below
    padded: PropTypes.bool
};

Accordion.defaultProps = {
    type: 'default',
    initOpen: false,
    padded: false
};
