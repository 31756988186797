import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Subtitle, Caption } from '../modules/Typography';
import twitterFeedParser from '../../utils/twitterFeedParser';

const TwitterFeed = ({ feed, image }) => {
    const [feedText, setFeedText] = useState(feed.text);
    const [hyperlink, setHyperlink] = useState([]);

    useEffect(() => {
        const [hyperlink, truncatedFeed] = twitterFeedParser(feed.text);
        if (hyperlink.length > 0) {
            setHyperlink(hyperlink);
            setFeedText(truncatedFeed);
        }
    }, []);

    return (
        <div className='Feed__container'>
            <header className='Feed__header'>
                <img src={image} className='Feed__image' />
                <div className=''>
                    <a href={`${feed.user.url}`} className='Feed__url' target='_blank' rel='noreferrer'>
                        <div className=''>
                            <Subtitle className='Feed__title'>{feed.user.name}</Subtitle>
                        </div>
                        <div className=''>
                            <Caption className='Feed__subtitle'>{feed.user.username}</Caption>
                            <Caption className='Feed__subtitle'>.</Caption>
                            <Caption className='Feed__subtitle'>{moment(feed.created_at).format('MMM Do')}</Caption>
                        </div>
                    </a>
                </div>
            </header>
            <div>
                <Caption className='Feed__text'>
                    {feedText}
                    {hyperlink.length !== 0
                        ? hyperlink.map((link, ind) => <Fragment key={ind}><a href={link} target='_blank' rel='noreferrer'>{link}</a> </Fragment>)
                        : null}
                </Caption>
            </div>
        </div>
    );
};

TwitterFeed.propTypes = {
    feed: PropTypes.object,
    image: PropTypes.string
};

export default TwitterFeed;
