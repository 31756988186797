import { gql } from '@apollo/client';

export const GET_HOME = gql`
query homePage {
	homePage {
		data {
			attributes {
		  		headerTitle
		  		headerImage {
					image {
						data {
							attributes {
								url
							}
						}
					}
					copyright
					filmTitle
					business {
						data {
							attributes {
								slug
								logo {
									data {
										attributes {
											url
										}
									}
								}
							}
						}
					}
		  		}
				featured_news(sort: "date:desc") {
					data {
						id
						attributes {
							title
							date
							cardImage {
								data {
									attributes {
										url
									}
								}
							}
							poster {
								data {
									attributes {
										url
									}
								}
							}
							slug
						}
					}
				}
			}
		}
  	}
	newsItemses(pagination: {start: 0, limit: 4}, sort: "date:desc") {
		data {
			id
			attributes {
				title
				date
				slug
				cardImage {
					data {
						attributes {
							url
						}
					}
				}
				poster {
					data {
						attributes {
							url
						}
					}
				}
			}
		}
	}
}
  
`;

export const UPDATE_HOME = gql`
	mutation updateHome($response: String) {
		updateHome(response: $response) {
			response
		}
	}
`;
