import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Paper from '../modules/Paper';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Col } from '../modules/Grid';
import { Heading, Subtitle, Caption } from '../modules/Typography';

const NewsArchive = props => {
    const { size } = useContext(WidthContext);
    const { slug, title, date } = props.news.attributes;

    return (
        <Col className='newsArchive' xs={12} sm={5} md={5} lg={3}>
            <Paper className='NewsArchive__card'>
                <Link to={`/newsdetail/${slug}`}>
                    <div className='NewsArchive__details flex column align-flex-start'>
                        <div className='flex column align-flex-start'>
                            {size !== 'xl' && size !== 'xxl' ? (
                                <Subtitle className='NewsCard__details__name archive' number={1}>{title}</Subtitle>
                            ) : (
                                <Heading className='NewsCard__details__name' number={6}>{title}</Heading>
                            )}
                            <Caption className='NewsCard__details__date'>{moment(date).format('MMMM DD, YYYY')}</Caption>
                        </div>
                    </div>
                </Link>
            </Paper>
        </Col>

    );
};

NewsArchive.propTypes = {
    news: PropTypes.object,
    title: PropTypes.string,
    date: PropTypes.string
};

export default NewsArchive;
