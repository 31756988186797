import { gql } from '@apollo/client';

export const GET_ABOUT = gql`
  query aboutPage {
    aboutPage {
      data {
        attributes {
          headerTitle
          headerImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          businessHeader
          businessDescription
          businesses(sort: "order:asc") {
            data {
              id
              attributes {
                name
                logo {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                description
                slug
                order
                website
                facebook
                twitter
                instagram
                youtube
              }
            }
          }
          textBlock {
            id
            header
            paragraph
          }
          timeline {
            id
            event {
              title
              orderDate
              id
              description
              date
            }
          }
          leaders(pagination: { start: 0, limit: 25 }) {
            data {
              id
              attributes {
                name
                title
                order
                bio
                displayImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          films {
            data {
              id
              attributes {
                title
                directedBy
                distribution
                releaseInfo
                website
                category
                cast
                genre
                synopsis
                rating
                business {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                releaseDate
                poster {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FILM_DETAIL = gql`
  query films($slug: String!) {
    films(filters: { slug: { eq: $slug } }, publicationState: PREVIEW) {
      data {
        id
        attributes {
          title
          directedBy
          cast
          genre
          synopsis
          rating
          business {
            data {
              id
              attributes {
                name
              }
            }
          }
          releaseDate
          releaseInfo
          distribution
          website
          category
          poster {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_BIZ_DETAIL = gql`
  query businesses($slug: String!) {
    businesseses(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          name
          logo {
            data {
              attributes {
                url
              }
            }
          }
          description
          slug
          website
          facebook
          twitter
          instagram
          youtube
          timeline {
            event {
              title
              date
              orderDate
              description
            }
          }
          films {
            data {
              id
              attributes {
                title
                directedBy
                cast
                genre
                synopsis
                rating
                business {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                releaseDate
                releaseInfo
                distribution
                website
                category
                poster {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          news_items(sort: "date:desc", pagination: { start: 0, limit: 4 }) {
            data {
              id
              attributes {
                title
                date
                cardImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                poster {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                slug
                businesses {
                  data {
                    attributes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LEADERS = gql`
  query leaders {
    leaders {
      data {
        id
        attributes {
          name
          title
          order
          slug
          bio
          displayImage {
            data {
              attributes {
                url
              }
            }
          }
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LEADERS_PREVIEW = gql`
  query leaders {
    leaders(publicationState: PREVIEW) {
      data {
        id
        attributes {
          name
          title
          slug
          bio
          displayImage {
            data {
              attributes {
                url
              }
            }
          }
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
