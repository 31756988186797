import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Business = props => {
    const { logo, slug } = props.business.attributes;

    return (
        <Link to={`/business/${slug}`}>
            <div className='About__businesses__logo button'>
                <img src={logo.data ? logo.data.attributes.url : '#'} />
            </div>
        </Link>
    );
};

Business.propTypes = {
    business: PropTypes.object
};

export default Business;
