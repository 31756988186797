import React, { useEffect, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import parseMarkdown from '../../utils/markdownParser';
import { GET_NEWS_DETAIL } from '../../queries';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading, Body, Caption } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
// import FeaturedTitles from '../home/FeaturedTitles';
import Loading from '../modules/Loading';
import moment from 'moment';
// import Button from '../modules/Button';

const NewsDetail = props => {
    const { slug } = useParams();
    const { isMobile } = useContext(WidthContext);
    const { data, loading } = useQuery(GET_NEWS_DETAIL, { variables: { slug: slug } });
    const { date, story, title } = data ? data.newsItemses.data[0].attributes : {};
    var hasIframe = false;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function refreshPage () {
        if (hasIframe) { location.reload(); }
    }

    window.addEventListener('OneTrustGroupsUpdated', refreshPage);

    if (loading) return <Loading />;

    return (
        <div className='About'>
            <Helmet><title>News</title></Helmet>
            <div className='Home__hero__small flex column center' style={{ background: 'linear-gradient(#2338F4, #00B7C7)' }}>
                <Container className='About__content'>
                    <Row>
                        <Col>
                            <Heading className='Home__hero__title Home__hero__news' block number={isMobile ? 4 : 3}>{title}
                            </Heading>
                            <Heading className='Home__hero__date Home__hero__news center' block number={6}>{moment(date).format('MMMM D, YYYY')}</Heading>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='About__content__news'>
                <Row>
                    <Col sm={12}>
                        <div className='Home__section'>
                            <Body block />
                            {
                                story.map((block, id) => {
                                    switch (block.__typename) {
                                    case 'ComponentImageComponentImageComponent':
                                        return (
                                            <div className='padded flex center' key={id}>
                                                <img src={block.image.data ? block.image.data.attributes.url : '#'} />
                                            </div>
                                        );
                                    case 'ComponentGalleryGalleryImages':
                                        return (
                                            <div className='Business__news'>
                                                {block.galleryImage.data.map((img, imageId) =>
                                                    <div className='Business__news__image padded' key={imageId}>
                                                        <img className='padded' src={img.attributes != null ? img.attributes.url : '#'} />
                                                        <Caption className='padded'>{img.attributes != null ? img.attributes.caption : ''}</Caption>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    case 'ComponentTextBlockTextBlock':
                                        var newsText = block.text;
                                        // console.log("newsText before: " + newsText);
                                        hasIframe = newsText.indexOf('<iframe') != -1;

                                        if (OnetrustActiveGroups && !OnetrustActiveGroups.includes('C0004')) {
                                            while (newsText.indexOf('<iframe') != -1) {
                                                newsText = newsText.substring(0, newsText.indexOf('<iframe')) +
                                                    newsText.substring(newsText.indexOf('</iframe>') + 9);
                                            }
                                        }

                                        // console.log("newsText after: " + newsText);
                                        return (
                                            <div key={id}>
                                                {parseMarkdown(newsText)}
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );
};

NewsDetail.propTypes = {

};

export default NewsDetail;
