import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { WidthContext } from '../../contexts/WidthContextProvider';
// import { Link } from 'react-router-dom';
import FeatureTitle from './FeatureTitle';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FeaturedTitles = props => {
    const { isMobile } = useContext(WidthContext);

    const sortedFilms = props.films ? [...props.films].sort(function (a, b) {
        return new Date(b.attributes.releaseDate) - new Date(a.attributes.releaseDate);
    }) : [];

    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        initialSlide: 0,
        slidesToShow: isMobile ? 1 : 3,
        slidesToScroll: isMobile ? 1 : 3
    };

    return (
        <div className='FeaturedTitles'>
            <Slider {...settings} className='FeaturedTitles__slider'>
                {sortedFilms.map((film) => (
                    <div key={film.id}>
                        <FeatureTitle film={film} title={film.attributes.title} image={film.attributes.poster.data ? film.attributes.poster.data.attributes.url : '#'} date={film.attributes.releaseDate} cast={film.attributes.cast} director={film.attributes.directedBy} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

FeaturedTitles.propTypes = {
    films: PropTypes.array
};

export default FeaturedTitles;
