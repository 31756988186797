import React, { useEffect, useState, useContext, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Paginator from 'react-hooks-paginator';
import { Helmet } from 'react-helmet-async';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { GET_NEWS, GET_NEWS_ITEMS } from '../../queries';
import { Heading, Body } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import FeaturedNewsBlock from '../home/FeaturedNewsBlock';
import NewsArchive from '../home/NewsArchive';
import Icon from '../modules/Icon';
import Button from '../modules/Button';
import Loading from '../modules/Loading';

const News = props => {
    const { isMobile, size } = useContext(WidthContext);
    const { data: newsData, loading } = useQuery(GET_NEWS_ITEMS);
    const { data: newsPage } = useQuery(GET_NEWS);
    const [archiveOpen, setArchiveOpen] = useState(true);
    const { headerImage, headerTitle } = newsPage ? newsPage.newsPage.data.attributes : {};
    const pageLimit = 20;
    const [offset, setOffset] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState([]);

    const sortedNews = newsData ? [...newsData.newsItemses.data].sort((a, b) => Date.parse(b.attributes.date) - Date.parse(a.attributes.date)) : [];

    const handleOpen = () => {
        setArchiveOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCurrentData(data.slice(offset, offset + pageLimit));
    }, [offset]);

    useEffect(() => {
        setData(sortedNews);
        window.scrollTo(0, 0);
    }, [archiveOpen]);

    if (loading || !newsPage) return <Loading />;

    return (
        <div className='News'>
            <Helmet><title>Studio News</title></Helmet>
            <div className='Home__hero center flex column' style={{ backgroundImage: `url("${headerImage && headerImage.data.attributes.url}")` }}>
                <Body className='Home__hero__caption'>{headerTitle}</Body>
            </div>
            <Container className='Home__content news'>
                <div className='Home__section'>
                    <Heading number={isMobile ? 5 : 4}>{archiveOpen ? 'Recent News' : 'News Archive'}</Heading>
                    <div className='heading--underline' />
                    {archiveOpen ? (
                        <FeaturedNewsBlock data={size === 'xxl' ? sortedNews.slice(0, 10) : sortedNews.slice(0, 8)} />
                    ) : (
                        <div className='NewsArchive'>
                            <>
                                {currentData.map((news, idx) => (
                                    <Fragment key={idx}>
                                        <NewsArchive key={news.id} news={news} />
                                    </Fragment>
                                )

                                )}
                            </>
                        </div>
                    )}
                </div>
                {archiveOpen ? (
                    <Button type='secondary' onClick={handleOpen} className='Home__link' color='primary-light' block>View news archive</Button>
                ) : (
                    <>
                        <div className='flex flex-start'>
                            <Icon className='button' name='caret-left' onClick={() => setCurrentPage(currentPage - 1)} />
                            <Paginator
                                totalRecords={sortedNews.length}
                                pageLimit={pageLimit}
                                pageNeighbours={1}
                                setOffset={setOffset}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                            <Icon className='button' name='caret-right' onClick={() => setCurrentPage(currentPage + 1)} />
                        </div>
                    </>
                )}
            </Container>
        </div>
    );
};

News.propTypes = {

};

export default News;
