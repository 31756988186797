import { gql } from '@apollo/client';

export const GET_NEWS = gql`
	query newsPage {
		newsPage {
            data { 
                attributes {
                    headerTitle
                    headerImage{
                        data { 
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
		}
	}
`;
export const GET_NEWS_ITEMS = gql`
	query newsItems {
        newsItemses(pagination: {start: 0, limit: 40}, sort: "date:desc") {
            data {
                id
                attributes {
                    title
                    cardImage {
                        data { 
                            attributes {
                                url
                            }
                        }
                    }
                    poster {
                        data { 
                            attributes {
                                url
                            }
                        }
                    }
                    slug
                    date
                    story {
                        ...on ComponentTextBlockTextBlock{
                            id
                            text
                        }
                        ...on ComponentImageComponentImageComponent{
                            id
                            image{
                                data { 
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                    businesses{
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }  
	}
`;

export const GET_NEWS_DETAIL = gql`
  query newsItems($slug: String!) {
    newsItemses (filters: {slug: { eq: $slug}
    }, publicationState: PREVIEW) {
        data {
            id
            attributes {
                title
                cardImage{
                    data { attributes {
                        url
                    }}
                }
                poster{
                    data { attributes {
                        url
                    }}
                }
                slug
                date
                story{
                    ...on ComponentTextBlockTextBlock{
                        id
                        text
                    }
                    ... on ComponentGalleryGalleryImages{
                        id
                        galleryImage {
                            data {
                                attributes {
                                    url
                                    caption
                                }
                            }
                        }
                    }
                    ...on ComponentImageComponentImageComponent{
                        id
                        image {
                            data { attributes {
                                url
                            }}
                        }
                    }
                }
                businesses{
                    data {
                        id
                        attributes {
                            name
                        }
                    }
                }
            }
        }
    }
  }
`;
