import disneyStudios from '../../images/DisneyStudios.jpg';
import animation from '../../images/DisneyAnimations.jpg';
import marvel from '../../images/Marvel.jpeg';
import pixar from '../../images/Pixar.jpg';
// import fox from '../../images/20th.jpg';
import broadway from '../../images/DisneyonBroadway.jpg';
import blueSky from '../../images/Blueskystudios.jpg';
import searchlight from '../../images/SearchlightPictures.jpg';
import lucasfilm from '../../images/Lucasfilm.jpg';

const feedConfig = {
    36947388: disneyStudios,
    63296944: animation,
    '750751206427860992': marvel,
    348632490: blueSky,
    3291841: searchlight,
    3223742785: lucasfilm,
    43192807: pixar,
    563888509: broadway
};

export default feedConfig;
