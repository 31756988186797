import React, { useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NewsItem from './NewsItem';
import Button from '../modules/Button';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FeaturedNews = props => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 3000
    };

    const news = props.news;

    return (
        <div className='FeaturedNews'>
            <Slider {...settings} className='FeaturedNews__slider'>
                {news.map((item) => (
                    <div key={item.id}>
                        <Link to={`/newsdetail/${item.attributes.slug}`}>
                            <NewsItem title={item.attributes.title} image={item.attributes.cardImage.data ? item.attributes.cardImage.data.attributes.url : (item.attributes.poster.data ? item.attributes.poster.data.attributes.url : '#')} />
                        </Link>
                    </div>
                ))}
            </Slider>
            <Link to='/news'>
                <Button type='secondary' className='Home__link' block color='primary-light'>View more news
                </Button>
            </Link>
        </div>
    );
};
FeaturedNews.propTypes = {
    news: PropTypes.array

};

export default FeaturedNews;
