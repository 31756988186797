import { gql } from '@apollo/client';

export const GET_FAQ = gql`
	query faqPage {
		faqPage {
                data { attributes { 
                headerTitle
                headerImage{
                    data { 
                        id
                        attributes { 
                            url
                        }
                    }
                }
                faqSection{
                    id
                    QA {
                        id
                        question
                        answer
                    }
                    title
                    description
                }
            }}
		}
	}
`;
